import React from "react";
import { graphql } from "gatsby";
import { globalQuery } from "types/graphql";
import ImageContent from "components/sections/imageContent";
import ListTitles from "components/sections/listTitles";
import HeroImage from "components/sections/hero";
import NumbersLine from "components/sections/numbersLine";
import OurClients from "components/sections/ourClients";
import IntroHome from "components/sections/introHome";
import {Seo} from "components/seo";
import {StaticImage} from "gatsby-plugin-image";

export default function Home({ data }: { data: globalQuery }) {
  const {
    allMarkdownRemark: { edges: sectionsData },
  } = data;
  return (
    <>
      {sectionsData.map(

        ({ node: {frontmatter: elem, html } }, index: number) => {
          if (elem.format == "introhome") {
            return (
              <React.Fragment key={index}>
                <Seo title={elem.metaTitle as string} description={elem.metaDescription} />
                <IntroHome title={elem.title} content={html} />
              </React.Fragment>
            )
          }
          if (elem.format == "bannerhome") {
            return (
              <ImageContent
                id="second"
                key={index}
                title={elem.title}
                subtitle={elem.subtitle}
                content={html}
                parallaxImages={elem.parallaxImages}
              />
            );
          }
          if (elem.format == "titles") {
            return (
              <ListTitles
                key={index}
                titles={elem.titles}
                listtitle={elem.listtitle}
                listcontent={elem.listcontent}
              />
            );
          }
          if (elem.format == "numbers") {
            return <NumbersLine key={index} listnumbers={elem.listnumbers} />;
          }
          if (elem.format == "heroimage") {
            return (
            <div key={index} className={"grid transition-image"} data-aos="custom">
              <StaticImage
                layout="fullWidth"
                alt="Equipe La Fabrique VingtCinq"
                src={'../../static/home/VingtCinq-Fabrique-digitale-equipe-office.jpg'}
              />
              <span className="transition-layer" data-key={index} style={{transitionDelay: '0.3s'}}/>
            </div>
            )
          }

          if (elem.format == "clients") {
            return (
              <OurClients key={index} title={elem.title} subtitle={elem.subtitle} />
            );
          }
          if (elem.format == "herohome") {
            return (
              <HeroImage
                key={index}
                title={elem.title}
                content={html}
                withTransition={false}
                buttonlabel={elem.buttonlabel}
                imageUrl={elem.imageUrl}/>
            )
          }
        })
      }
    </>
  )
}

export const pageQuery = graphql`query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/homepage/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          html
          frontmatter {
            format
            title
            subtitle
            titles
            listtitle
            parallaxImages { 
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              speed 
              direction
              alt 
            }
            listcontent
            buttonlabel
            imageUrl
            listnumbers
            metaTitle
            metaDescription
            metaImage
          }
        }
      }
    }
  }
  
`;
