import React from "react";
import { HeadingFrontMatter } from "types/graphql";
import ArrowDown from "assets/svg/arrow-down.svg";
import HomeSlider from "components/homeSlider";
import { scroller } from 'react-scroll';

type introHeadingProps = Omit<HeadingFrontMatter, "format"> & {
  content: string;
};

const scrollTo = () => {
  scroller.scrollTo('second', {
    duration: 600,
    delay: 0,
    smooth: 'easeInOutQuart'
  })
}

const introHome = ({ title, content }: introHeadingProps) => {
  return (
    <section className="relative flex flex-col lg:flex-row h-auto lg:h-screen">
      <div className="flex flex-row w-full lg:flex-nowrap flex-wrap">
        <div className="flex flex-col w-full lg:w-3/5 2xl:w-1/2 justify-center">
          <article className="mt-auto lg:pl-12 px-4 py-9 lg:pt-7 lg:pb-0">
            <p
              data-aos="custom"
              className="text-b3 mb-3 lg:mb-10 text-gray-700 3xl:text-xl fade">{title}
            </p>
            <div
              className='overflow-hidden'
              dangerouslySetInnerHTML={{ __html: content }} />
          </article>
          <div
            className="hidden lg:block w-full bg-gray-50 hover:bg-gray-100 py-3 lg:py-6 pl-2 lg:pl-10 mt-auto order-1 lg:order-none cursor-pointer transition-colors duration-05 ease-ease"
            onClick={scrollTo}
          >
            <a>
              <span className="label1 text-gray-600 flex items-center gap-x-2 select-none">
                <ArrowDown className="animate-fadeDown"/>
                scroll
              </span>
            </a>
          </div>
        </div>
        <figure className="w-full lg:w-2/5 2xl:w-1/2 h-[19rem] md:h-[34rem] lg:h-full relative">
          <div className="w-full h-full transition-image relative" data-aos="custom">
            <HomeSlider />
            <span className="transition-layer" />
          </div>
        </figure>
      </div>
    </section>
  );
};

export default introHome;
