import React from "react";
import Section from "../section";
import Heading from "../headings";
import ArrowRight from "assets/svg/arrow-right.svg";
import { Parallax } from 'react-scroll-parallax'

import { BannerFrontMatter } from "types/graphql";
import classNames from "classnames/bind";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

type imageContentProps = Omit<BannerFrontMatter, "format"> & {
  content: string
  reverse?: boolean
  length?: number
  sectionUrl?: string
  id?: string
  className?:string
  textFirst?: boolean
};

const imageContent = ({
    title,
    subtitle,
    content,
    imageUrl,
    parallaxImages,
    reverse,
    textFirst,
    length = 0,
    sectionUrl,
    id,
    className,
}: imageContentProps) => {
  const classFigure =
    length !== 0
      ? reverse
        ? " lg:mr-0 lg:ml-auto mx-auto"
        : " lg:ml-0 lg:mr-auto mx-auto"
      : "mx-auto";

  return (
    <Section
      id={id}
      className={classNames("bg-yellow overflow-hidden ", className,{
        'pt-10': length === 0
      })}
      full
    >
      <div className="grid grid-cols-1 md:grid-cols-2 lg:items-center gap-y-8 gap-x-28">
        { parallaxImages && parallaxImages.length > 0 && (
          <div className="relative w-full">
            { parallaxImages.map((item: any, index: number) =>
              {
                let image = getImage(item.image)

                return image ? <Parallax
                  key={index}
                  className={classNames('w-full block', { 'absolute top-0 left-0': index !== 1, 'relative z-1': index === 1, 'z-2': index >= 2})}
                  translateX={item.direction === 'horizontal' ? [0, item.speed] : [0, 0]}
                  translateY={item.direction !== 'horizontal' ? [item.speed, 0 ] : [0, 0]}
                >
                  <GatsbyImage
                    image={image}
                    alt={item.alt ?? 'image'}
                  />
              </Parallax> : <></>})}
          </div>
        )}
        {!parallaxImages && imageUrl && (
          <img
            className={classFigure}
            src={imageUrl}
          />
        )}
        <div
          className={classNames("max-w-prose ", {
            "lg:col-start-1 lg:row-start-1" : reverse,
            '-order-1 lg:order-none': textFirst
          })}
        >
          {subtitle && <Heading className="my-6 lg:mt-0">{subtitle}</Heading>}
          {title && (
            <Heading className="mb-2 transition-text" headingLevel={"h2"}>
              <span className="transition-content">{title}</span>
              <span className="transition-layer yellow"/>
            </Heading>
          )}
          {content && (
            <div
              data-aos="custom"
              className="image-content overflow-hidden translate"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          {sectionUrl && (
            <button
              className="bg-gray-900 rounded-full w-16 h-16 mt-2"
              type="button"
            >
              <ArrowRight className="p-4 fill-yellow" />
            </button>
          )}
        </div>
      </div>
    </Section>
  );
};

export default imageContent;
